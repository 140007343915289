import React from "react"

// material-ui
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// project imports
import Container from "../components/Container"
import ContentSection1 from "../screens/about/ContentSection1"
import ContentSection2 from "../screens/about/ContentSection2"

// styles
import styled from "@emotion/styled"

function CompanySection(props) {
  const { datasource } = props
  const { contentCompanyCulture } = datasource
  return (
    <React.Fragment>
      {contentCompanyCulture.map((item, i) => {
        return (
          <Box sx={{ bgcolor: `${item.contentBackColor}` }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row nowrap",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box sx={{ order: `${item.contentImageOrder}` }}>
                  <img
                    src={item.contentImage}
                    width="600"
                    height="400"
                    alt=""
                  />
                </Box>
                <Box sx={{ order: `${item.contentTextOrder}`, pr: 5, pl: 5 }}>
                  <Typography
                    component="h1"
                    variant="h3"
                    align="center"
                    color="textPrimary"
                    gutterBottom
                  >
                    {item.contentPrimaryText}
                  </Typography>
                  <Typography
                    component="h1"
                    variant="h5"
                    align="center"
                    color="textPrimary"
                    gutterBottom
                  >
                    {item.contentSecondaryText}
                  </Typography>
                  <Typography variant="body1" color="textPrimary" gutterBottom>
                    {item.contentText}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )
      })}
    </React.Fragment>
  )
}

export default () => (
  <React.Fragment>
    <ContentSection1 />
    <Container>
      <ContentSection2 />
    </Container>
  </React.Fragment>
)
