/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import React from "react"

// material-ui
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// styles
const root = css`
  width: 100%;
  height: 450px;
  position: relative;
  background: linear-gradient(
    to bottom right,
    rgba(0, 94, 160, 1),
    rgb(124, 80, 157)
  );
  backgroundsize: cover;
  backgroundattachment: fixed;
  backgroundposition: center;
  color: #fff;
`

const headerTextWrapper = css`
  display: inline-block;
  position: absolute;
  top: 45%;
  margin: auto 120px;
  left: 0;
  right: 0;
`

const headerImageWrapper = css`
  display: inline-block;
  position: absolute;
  top: 20%;
  right: 10%;
`

const ContentSection1 = (props) => {
  return (
    <Box css={root}>
      <div css={headerTextWrapper}>
        <Typography
          variant="h1"
          key={1}
          sx={{ fontSize: 36, fontWeight: "medium", mb: 5 }}
          gutterBottom
        >
          {props.title}
        </Typography>
        <Typography
          variant="h1"
          key={2}
          sx={{ fontSize: 20, fontWeight: "medium" }}
        >
          {props?.subtitle}
        </Typography>
      </div>

      <div width="300" css={headerImageWrapper}>
        <img key={2} src={props?.subjectImage} />
      </div>
      {props.children}
    </Box>
  )
}

export default ContentSection1
